import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import CommonForm from '../../../components/CommonForm/CommonForm';
import CommonInput from '../../../components/CommonInput/CommonInput';
import Checkbox from '../../../components/Checkbox/Checkbox';

import LogoSvg from '../../../public/svg/logo.svg'
import towerImg from '../../../public/img/catalog/tower.png'

import { sendCatalogRequest } from '../../../api/wagtail';


export default function CatalogSection({ catalogTitle, siteSetting, csrfToken, className='' }) {
  const [formShown, setFormShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const handleSubmit = (data) => {
    setLoading(true);
    return sendCatalogRequest(data, { csrfToken })
  }

  const handleSuccess = (data) => {
    setPdfUrl(data.pdfUrl);
    setLoading(false);
  }

  return (
    <section className={`w-full sm:py-vw-80 py-vw-52 ${className}`}>
      <div className="centered container-main h-full !px-0">
        <div className="main-gradient w-full sm:h-vw-88 h-vw-272 sm:rounded-vw-5xl rounded-vw-7xl relative">
          <div
            className={`
              flex sm:flex-row flex-col justify-between items-center
              absolute inset-0 z-10 transition-all
              sm:overflow-unset overflow-hidden
              sm:px-vw-14 px-vw-20 sm:py-0 pt-vw-20 pb-vw-32
            `}
            style={{
              visibility: (!formShown && !loading) ? 'visible' : 'hidden',
              opacity: (!formShown && !loading) ? 1 : 0
            }}
          >
            <h2 className="sm:w-vw-168 sm:text-vw-6.5xl text-vw-7.75xl leading-tight">
              {catalogTitle}
            </h2>

            <div className="sm:w-vw-96 w-vw-160 absolute sm:right-vw-20 -right-vw-8 bottom-0">
              <Image
                src={towerImg}
                alt="сергей петербургский | топ-10 новостроек санкт-петербурга"
                sizes="30vw"
              />
            </div>

            <button type="button" className="button-primary relative z-10 sm:px-vw-24 px-vw-36" onClick={() => setFormShown(true)}>
              Получить
            </button>
          </div>

          <div
            className="absolute inset-0 z-10 transition-all"
            style={{
              visibility: (formShown && !pdfUrl && !loading) ? 'visible' : 'hidden',
              opacity: (formShown && !pdfUrl && !loading) ? 1 : 0
            }}
          >
            <CommonForm
              defaultValues={{
                name: '',
                phone: '',
                policy: true
              }}
              onSubmit={handleSubmit}
              onSuccess={handleSuccess}
              className="flex sm:flex-row flex-col w-full h-full px-vw-14"
            >
              {({ isSubmitting }) => (
                <>
                  <div className="desktop-only centered-y w-vw-72 h-full">
                    <h4 className="text-vw-5xl leading-tight">
                      Оставьте<br />контактные<br />данные
                    </h4>
                  </div>

                  <div className="flex sm:flex-row flex-col items-center h-full sm:pt-0 pt-vw-72 sm:ml-auto">
                    <div className="flex flex-col">
                      <CommonInput
                        name="name"
                        rules={{ required: true }}
                        type="text"
                        placeholder="Имя"
                      />

                      <CommonInput
                        name="phone"
                        rules={{ required: true }}
                        type="tel"
                        placeholder="Телефон"
                        className="mt-vw-4"
                      />
                    </div>

                    <div className="flex flex-col items-center sm:pt-vw-16 pt-vw-40 sm:ml-vw-20">
                      <button
                        type="submit"
                        className={`sm:w-vw-80 button-primary sm:!px-0 px-vw-16 ${isSubmitting ? 'pointer-events-none' : ''}`}
                      >
                        Перейти к скачиванию
                      </button>

                      <Checkbox
                        name="policy"
                        rules={{ required: true }}
                        text={
                          <>
                            Нажимая на кнопку, вы даете согласие<br />на обработку <Link href={siteSetting.privacyPolicyPage.href} className="underline">персональных данных</Link>
                          </>
                        }
                        className="sm:mt-vw-6 mt-vw-10"
                      />
                    </div>
                  </div>
                </>
              )}
            </CommonForm>
          </div>

          <div
            className={`
              flex sm:flex-row flex-col justify-between items-center
              absolute inset-0 z-10 transition-all
              sm:px-vw-14 px-vw-20 sm:py-0 py-vw-28
            `}
            style={{
              visibility: pdfUrl ? 'visible' : 'hidden',
              opacity: pdfUrl ? 1 : 0
            }}
          >
            <div>
              <p className="sm:text-vw-3xl text-vw-5xl">PDF</p>

              <h2 className="sm:w-vw-168 sm:text-vw-6.5xl text-vw-7.75xl leading-tight">
                {catalogTitle}
              </h2>
            </div>

            <a
              href={pdfUrl}
              target="_blank"
              rel="noreferrer noopener"
              className="block sm:button-primary button-secondary relative z-10 sm:px-vw-24 px-vw-36"
            >
              Скачать
            </a>
          </div>

          <div
            className="centered main-gradient sm:rounded-vw-5xl rounded-vw-7xl absolute inset-0 z-20 transition-all"
            style={{
              visibility: loading ? 'visible' : 'hidden',
              opacity: loading ? 1 : 0
            }}
          >
            <LogoSvg className="w-vw-44 animate-spin" />
          </div>
        </div>
      </div>
    </section>
  )
}
