import LogoSvg from '../../../public/svg/logo.svg'


export default function FreeSection() {
  return (
    <section className="centered sm:h-vw-152 h-vw-200 w-full">
      <div className="relative">
        <div className="centered sm:w-vw-168 w-vw-176 sm:h-vw-60 h-vw-80 bg-primary sm:rounded-vw-5xl rounded-vw-7xl rotate-[-6deg]">
          <h2 className="sm:text-vw-4.5xl text-vw-5xl leading-tight">
            Для вас это бесплатно,<br />комиссию платит<br />застройщик
          </h2>
        </div>

        <LogoSvg className="sm:w-vw-44 w-vw-56 absolute sm:-right-vw-32 right-vw-4 -bottom-vw-16" />
      </div>
    </section>
  )
}
