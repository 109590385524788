import Script from 'next/script'


export default function ReviewsSection({ className='' }) {
  return (
    <section id="reviews" className={className}>
      <div className="container-main">
        <h2 className="text-vw-7.75xl">Отзывы</h2>
        <p className="sm:text-vw-2xl text-vw-3xl">Собраны из открытых источников</p>

        <div id="goodvice" className="w-full sm:h-vw-88 h-vw-336 mt-vw-6">

        </div>

        <Script
          id="show-gv"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            (function() {
              const url = "aHR0cHM6Ly9iYWNrZW5kLXQ0M3U2bWM2d3EtZXcuYS5ydW4uYXBwL2FwaS9nZXRXaWRnZXQ/Y2lkPWQxTGJGWDdab0h2SXMwT05Dcm5tJmlkPXdydlEwOWNSSEdIYVNUcTRnR2lm";
              (function(document, id, source) {
                let req = new XMLHttpRequest();
                req.addEventListener("load", reqListener);
                req.open("GET", source);
                req.send();
                function reqListener() {
                  if (this.readyState == 4 && this.status == 200) {
                    let container = document.getElementById(id);
                    container.innerHTML = this.responseText;
                    let scripts = container.getElementsByTagName("script");
                    for (let i = 0; i < scripts.length; i++) {
                      if (scripts[i].type === "application/ld+json") continue;
                      eval(scripts[i].innerText);
                    }
                  } else if (this.status == 503) {
                    console.error(this.statusText, "Ошибка загрузки виджета от Goodvice");
                  }
                }
              })(document, "goodvice", "https://functions.yandexcloud.net/d4e1s2enrh61ft8bd83b?url=" + url);
            })();
            `,
          }}
        />
      </div>
    </section>
  )
}
