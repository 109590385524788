import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";


function CommonForm({ defaultValues, onSubmit, onSuccess, onError, onWatch, className = '', children }) {
  const {
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, errors, isDirty },
    ...methods
  } = useForm({ defaultValues });

  const submit = (data) => {
    return onSubmit(data)
      .then((resp) => {
        onSuccess?.(resp.json)
      })
      .catch((err) => {
        if (typeof err.data.error === 'object') {
          if (err.data.error.details) {
            setError('root', {
              type: err.response.status,
              message: err.response.statusText
            })
          } else {
            for (let [name, errData] of Object.entries(err.data.error)) {
              setError(name, {
                type: 'server',
                message: Array.isArray(errData) ? errData.join(', ') : errData
              })
            }
          }

          onError?.(err.data.error)
        }
      })
  }

  const manualSubmit = () => {
    return handleSubmit(submit)()
  }

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(data, name, type)
      }
      onWatch?.(data)
    });
    return () => subscription.unsubscribe();
  })

  return (
    <FormProvider {...{ watch, errors, setError, isSubmitting, ...methods }}>
      <form className={className} onSubmit={handleSubmit(submit)}>
        {
          typeof children === 'function'
            ? children({ submit: manualSubmit, isSubmitting, errors, isDirty })
            : children
        }
      </form>
    </FormProvider>
  )
}

CommonForm.defaultProps = {};

CommonForm.propTypes = {};


export default CommonForm