import { useRef } from 'react';
import { motion, useMotionValue, useSpring, useTransform, useScroll, useMotionValueEvent } from 'framer-motion'
import Image from 'next/image';

import winImage from '../../../public/img/win.png'


const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
const invlerp = (x, y, a) => clamp((a - x) / (y - x));


export default function WeInNumbers({ className='' }) {
  const sectionRef = useRef(null);
  const { scrollY } = useScroll()
  const progress = useMotionValue(0);
  const val = useSpring(progress);

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (sectionRef.current) {
      progress.set(
        invlerp(
          sectionRef.current.offsetTop - window.innerHeight - 200,
          sectionRef.current.offsetTop + sectionRef.current.offsetHeight + 200,
          latest
        )
      )
    }
  })

  return (
    <section ref={sectionRef} className={`sm:overflow-hidden ${className}`}>
      <div className="sm:container-main">
        <div className="sm:px-0 px-main">
          <h2 className="text-vw-7.75xl">Мы в цифрах</h2>
        </div>

        <div className="w-full h-vw-160 relative sm:mx-0 -mx-vw-8">
          <motion.div
            className="w-vw-144 h-vw-144 bg-primary rounded-vw-7xl p-vw-14 mt-vw-6 sm:ml-vw-16 ml-vw-32"
            style={{
              translateX: useTransform(val, [0, 1], ['-5vw', '2vw']),
              translateY: useTransform(val, [0, 1], ['-4vw', '8vw']),
              rotate: useTransform(val, [0, 1], [-10, 6])
            }}
          >
            <p className="text-vw-5xl">
              Помогаем<br />сэкономить до<br />20% от стоимости
            </p>
          </motion.div>

          <div className="sm:w-vw-128 w-vw-144 absolute sm:top-vw-48 top-vw-96 sm:left-vw-36 -left-vw-16 sm:z-0 z-10 sm:rotate-[1deg] rotate-[-5deg]">
            <motion.div
              className="w-full h-full"
              style={{
                translateX: useTransform(val, [0, 1], ['-5vw', '2vw']),
                translateY: useTransform(val, [0, 1], ['4vw', '2vw']),
                rotate: useTransform(val, [0, 1], [-5, 0])
              }}
            >
              <Image
                src={winImage}
                alt="сергей петербургский | с нами купили на сумму 780 млрд. рублей"
                sizes="40vw"
              />
            </motion.div>
          </div>

          <motion.div
            className={`
              flex w-vw-144 sm:h-vw-128 h-vw-144
              bg-secondary rounded-vw-7xl
              absolute sm:top-vw-20 top-[unset] sm:bottom-[unset] -bottom-vw-272 sm:right-vw-4 right-vw-14
              p-vw-14
            `}
            style={{
              translateX: useTransform(val, [0, 1], ['5vw', '0vw']),
              translateY: useTransform(val, [0, 1], ['5vw', '0vw']),
              rotate: useTransform(val, [0, 1], [10, -1])
            }}
          >
            <p className="text-vw-5xl text-white mt-auto">
              С нами купили<br />на сумму 780<br />млрд. рублей
            </p>
          </motion.div>
        </div>

        <div className="centered w-full sm:h-vw-168 h-vw-192 relative z-20 overflow-hidden sm:-mt-vw-6 -mt-vw-64 pl-vw-36">
          <motion.div
            className="flex w-vw-144 sm:h-vw-128 h-vw-144 bg-gray-light rounded-vw-7xl p-vw-14 sm:ml-vw-16 sm:mr-0 -mr-vw-20"
            style={{
              translateX: useTransform(val, [0, 1], ['-4vw', '2vw']),
              translateY: useTransform(val, [0, 1], ['-8vw', '0vw']),
              rotate: useTransform(val, [0, 1], [8, -4])
            }}
          >
            <p className="text-vw-5xl mt-auto">
              Одобрили<br />более 100 ипотек<br />в 90% случаев
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  )
}
