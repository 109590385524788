import PropTypes from 'prop-types';
import { useFormContext, get } from 'react-hook-form';


function CommonInput({ name, rules, type, placeholder, className='' }) {
  const { register, errors } = useFormContext();
  const error = get(errors, name)

  return (
    <div className={`sm:w-vw-80 w-vw-120 ${className}`}>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className={`
          sm:w-vw-80 w-vw-120
          sm:text-vw-xl text-vw-4xl text-center
          sm:border-3 border-2 ${error ? 'border-red-700' : 'border-black'}
          bg-transparent sm:rounded-vw-3xl rounded-vw-5xl
          sm:py-vw-5 py-vw-7 placeholder:text-black/40
        `}
        {...register(name, rules)}
      />

      {error && (
        <p className="text-vw-lg text-red-700 leading-none text-center mt-vw-1">{error.message}</p>
      )}
    </div>
  )
}

CommonInput.defaultProps = {};

CommonInput.propTypes = {};


export default CommonInput;
