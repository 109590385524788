import { useFormContext, get } from "react-hook-form";


function RadioGroup({ name, rules, options }) {
  const { register, errors } = useFormContext()
  const error = get(errors, name)

  return (
    <div className="flex flex-col items-start">
      {options.map((opt, idx) => (
        <label key={idx} className={`flex items-center cursor-pointer ${idx !== 0 ? 'sm:mt-vw-2 mt-vw-6' : ''}`}>
          <input className="peer hidden" type="radio" value={opt.value} {...register(name, rules)} />

          <div
            className={`
              peer-checked:[&>*]:opacity-100
              centered flex-shrink-0 sm:w-vw-10 w-vw-20 sm:h-vw-10 h-vw-20
              rounded-full sm:border-4 border-3 ${error ? 'border-red-700' : 'border-black'}
            `}
          >
            <div className="sm:w-vw-7 w-vw-12 sm:h-vw-7 h-vw-12 bg-white rounded-full opacity-0 transition-opacity" />
          </div>

          <p className="sm:text-vw-2xl text-vw-5xl sm:ml-vw-3 ml-vw-4 mb-vw-2">
            {opt.label}
          </p>
        </label>
      ))}
    </div>
  )
}

RadioGroup.defaultProps = {};

RadioGroup.propTypes = {};


export default RadioGroup;
