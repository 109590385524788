import { useState } from 'react'
import Image from 'next/image'


export default function BonusesSection({ bonuses, className='' }) {
  const [moreHidden, setMoreHidden] = useState(true);

  return (
    <section id="bonuses" className={className}>
      <div className="container-main">
        <h2 className="text-vw-7.75xl leading-[1.2]">
          Покупая квартиру<br />с нами вы получаете
        </h2>

        <div className="sm:mt-vw-10 mt-vw-20">
          {bonuses.map((bonus, idx) => (
            <div
              key={idx}
              className={`
                flex w-full sm:h-vw-44
                bg-gray sm:rounded-vw-5xl rounded-vw-7xl
                relative 
                px-vw-16 sm:py-0 py-vw-4
                ${idx !== 0 ? 'mt-vw-2' : ''}
              `}
              style={{ display: idx + 1 > 4 ? moreHidden ? 'none' : '' : '' }}
            >
              <div className="flex sm:flex-row flex-col items-start w-full">
                <div className="flex-shrink-0 sm:h-full h-vw-32 aspect-square sm:p-vw-4 py-vw-3">
                  <div className="w-full h-full relative">
                    <Image
                      src={bonus.logo.src}
                      alt={bonus.logo.alt}
                      fill={true}
                      sizes="20vw"
                      className="object-contain object-left"
                    />
                  </div>
                </div>

                <div className="centered-y flex-shrink-0 sm:w-vw-144 w-vw-96 sm:h-full sm:pl-vw-20 sm:pb-0 pb-vw-6">
                  <p className="text-vw-3xl leading-tight w-full">{bonus.text}</p>
                </div>
              </div>

              <div className="justify-end absolute sm:top-1/2 top-vw-11 right-vw-16 sm:-translate-y-1/2">
                <p className="whitespace-nowrap sm:text-vw-4.5xl text-vw-5xl text-right">{bonus.bonus}</p>
              </div>
            </div>
          ))}
        </div>

        {(bonuses.length > 4 && moreHidden) && (
          <div className="flex justify-end mt-vw-2">
            <button className="button-primary" type="button" onClick={() => setMoreHidden(false)}>
              Показать все <span className="opacity-30">{bonuses.length}</span>
            </button>
          </div>
        )}
      </div>

    </section>
  )
}
