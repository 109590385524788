import PropTypes from 'prop-types';

import { basePageWrap } from '../BasePage';

import HeroSection from './sections/HeroSection';
import WhatWeDoSection from './sections/WhatWeDoSection';
import WeInNumbers from './sections/WeInNumbers';
import PathSection from './sections/PathSection';
import FreeSection from './sections/FreeSection';
import TeamSection from './sections/TeamSection';
import BonusesSection from './sections/BonusesSection';
import RequestSection from './sections/RequestSection';
import ReviewsSection from './sections/ReviewsSection';
import CatalogSection from './sections/CatalogSection';
import ContactsSection from './sections/ContactsSection';


function HomePage({ team, bonuses, catalogTitle, siteSetting, csrfToken }) {
	return (
		<>
			<article className="w-full">
				<HeroSection />
				<WhatWeDoSection />
				<WeInNumbers className="sm:mt-vw-16 -mt-vw-72" />
				<PathSection className="sm:mt-vw-48 mt-vw-224" />
				<FreeSection />
				<TeamSection team={team} />
				<BonusesSection bonuses={bonuses} className="sm:mt-vw-32 mt-vw-44" />
				<RequestSection {...{ siteSetting, csrfToken }} className="sm:mt-vw-24 mt-vw-32" />
				<ReviewsSection className="sm:mt-vw-24 mt-vw-28" />
				<CatalogSection {...{ catalogTitle, siteSetting, csrfToken }} className="mt-vw-12" />
				<ContactsSection siteSetting={siteSetting} className="sm:mb-vw-24 mb-vw-36" />
			</article>
		</>
	)
}

HomePage.defaultProps = {
	siteSetting: {},
	csrfToken: ''
};

HomePage.propTypes = {
	siteSetting: PropTypes.shape({
		email: PropTypes.string,
		phone: PropTypes.shape({
			international: PropTypes.string,
			rfc3966: PropTypes.string
		}),
		privacyPolicyPage: PropTypes.shape({
			slug: PropTypes.string
		})
	}),
	csrfToken: PropTypes.string
};


export default basePageWrap(HomePage);
