import { useRef, useState, useEffect } from 'react'

import BigThinPlusSvg from '../../../public/svg/big-thin-plus.svg';


const Collapsible = ({ title, text, onClick, className='' }) => {
  const textRef = useRef(null);
  const [isCollapsed, setCollapsed] = useState(true);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setMaxHeight(textRef.current.offsetHeight)
    }
  }, [textRef])

  return (
    <div className={`sm:rounded-vw-5xl rounded-vw-7xl overflow-hidden ${className}`}>
      <button
        className={`
          flex items-center justify-between w-full sm:h-vw-50 h-vw-72
          relative sm:px-vw-12 px-vw-16
          ${(text || onClick) ? '' : 'pointer-events-none'}
        `}
        onClick={() => onClick ? onClick() : setCollapsed(!isCollapsed)}
      >
        <h4
          className={`
            sm:text-vw-4.5xl text-vw-5xl text-start leading-[1.2]
            ${isCollapsed ? 'translate-y-0' : 'sm:-translate-y-vw-10'}
            transition-transform mr-vw-8
          `}
        >
          {title}
        </h4>

        {text && (
          <BigThinPlusSvg
            className={`flex-shrink-0 sm:w-vw-20 w-vw-24 ${isCollapsed ? '' : 'rotate-45'} transition-transform`}
          />
        )}
      </button>

      {text && (
        <div
          className={`
            transform
            ${isCollapsed ? 'opacity-0 translate-y-0' : 'sm:-translate-y-vw-48 -translate-y-vw-20 opacity-40'}
            transition-[max-height,transform,opacity]
            sm:px-vw-12 px-vw-16
          `}
          style={{
            maxHeight: isCollapsed
              ? maxHeight
                ? '0px'
                : ''
              : `${maxHeight}px`
          }}
        >
          <div
            ref={textRef}
            className="sm:w-vw-192 w-full sm:text-vw-lg text-vw-3xl sm:pt-0 pt-vw-10"
          >
            {text}
          </div>
        </div>
      )}
    </div>
  )
}


export default function PathSection({ className='' }) {
  return (
    <section className={className}>
      <div className="container-main">
        <h2 className="text-vw-7.75xl leading-[1.2]">
          Путь от нашего офиса<br />до вашей кровати
        </h2>

        <div className="sm:mt-vw-8 mt-vw-20">
          <Collapsible
            title="Анализируем ваши пожелания"
            text={
              <>
                Ужин начинается с аперитива, а покупка квартиры с правильного анализа ваших потребностей. Именно на этом этапе мы собираем для вас набор правильных фильтров, которые в будущем позволят выбрать лучшие варианты квартир. Сейчас я поделюсь с вами 3-мя базовыми вопросами, которые сэкономят вам более месяца поиска квартиры: какая цель вашей покупки? Какой максимальный бюджет готовы выделить? Сколько спален рассматриваете?<br /><br />После чего мы подробно разберем блок уточняющих вопросов как по самой квартире, так и по ее финансовой составляющей. Рассчитаем все возможные варианты: покупка в ипотеку с льготной ставкой, удобные рассрочки или приятная скидка за покупку наличкой.
              </>
            }
            className="bg-gray-light"
          />

          <Collapsible
            title="Подбираем квартиру под ваш запрос"
            text={
              <>
                Выслушав все ваши пожелания, мы приступаем к детальному подбору. На данном этапе вы получаете все возможные варианты ЖК и квартир, которые подходят именно под ваши предпочтения, без привязки к определенным застройщикам, поэтому вам будут предложены наилучшие варианты из всего рынка новостроек. Накопленный опыт, знание всех подводных камней и репутаций застройщиков позволят честно рассказать вам о всех плюсах и минусах конкретного проекта. За исключительно хвалебными отзывами обращаются к застройщику, за объективностью — к нам.<br /><br />Мы имеем доступ к резервным квартирам, которые вы не увидите ни на одном сайте застройщика или агентств. Также вы получите возможность забронировать квартиру до официального старта продаж.
              </>
            }
            className="bg-gray-light mt-vw-2"
          />

          <Collapsible
            title="Одобряем ипотеку"
            text={
              <>
                У каждого есть свои таланты и сильные стороны. Наши — в одобрении ипотеки. Благодаря большому опыту, сотрудничеству со всеми банками и ежедневному просмотру актуальных ипотечных программ, одобрим вас на самых лучших и выгодных на сегодняшний день условиях. Даже если у вас нет официального дохода. Даже если у вас нет первоначального взноса. Нерешаемых ситуаций нет. Наша задача — результат, ваша — сделать выбор.
              </>
            }
            className="bg-gray-light mt-vw-2"
          />

          <Collapsible
            title="Покупаем квартиру"
            text={
              <>
                Приобретение недвижимости в 99% случаях — это самая дорогая покупка в жизни человека и без стресса, к сожалению, здесь не обойтись. Поэтому для нас очень важно сопровождать вас на всех этапах сделки, как на подписании договора в офисе застройщика, так и на расставании с вашими кровными в офисе банка. Проверим все документы на наличие ошибок, поможем сэкономить на дополнительных расходах или вовсе избежать их. За все время работы, мы не допустили ни одного эксцесса, поэтому смело можете положиться на наш профессионализм.
              </>
            }
            className="bg-primary mt-vw-2"
          />

          <Collapsible
            title="Помогаем с приемкой квартиры"
            text={
              <>
                Наверное, каждый хотя бы раз видел на просторах интернета, как люди обнаруживают у себя в квартире различные огрехи с отделкой. Такое бывает, когда приемка квартиры осуществляется без надзора специалиста. Мастер поможет выявить все дефекты, которые могут быть упущены невооруженным глазом. Благодаря этому, вы сэкономите приличную сумму денег, так как все недочеты застройщик исправит за свой счёт. Данную услугу мы подарим вам в знак благодарности за выбор нашего агентства.
              </>
            }
            className="bg-gray-light mt-vw-2"
          />

          <Collapsible
            title="Бонусы от партнёров"
            onClick={() => document.getElementById('bonuses')?.scrollIntoView()}
            className="bg-secondary text-white mt-vw-2"
          />
        </div>
      </div>
    </section>
  )
}
