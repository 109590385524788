import PropTypes from 'prop-types';
import { useFormContext, get } from 'react-hook-form';


function Checkbox({ name, rules, text, className='' }) {
  const { register, errors } = useFormContext();
  const error = get(errors, name);

  return (
    <label className={`flex items-center ${className}`}>
      <input className="peer hidden" type="checkbox" {...register(name, rules)} />

      <div
        className={`
          centered sm:w-vw-10 w-vw-16 sm:h-vw-10 h-vw-16
          peer-checked:[&>*]:opacity-100 rounded-full
          sm:border-4 border-2
          ${error ? 'border-red-700' : 'border-black'}
        `}
      >
        <div className="sm:w-vw-7 w-vw-10 sm:h-vw-7 h-vw-10 bg-white rounded-full opacity-0 transition-opacity" />
      </div>

      <p className="sm:text-vw-base text-vw-2xl leading-none sm:ml-vw-3 ml-vw-5">
        {text}
      </p>
    </label>
  )
}

Checkbox.defaultProps = {};

Checkbox.propTypes = {};


export default Checkbox;
