import Image from 'next/image';
import Link from 'next/link';

import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import ArrowSvg from '../../../public/svg/arrow.svg'
import ArrowAngleSvg from '../../../public/svg/arrow-45.svg'


const MemberWrapper = ({ member, className='', children }) => {
  if (member.page) {
    return (
      <Link className={className} href={member.page.href}>
        {children}
      </Link>
    )
  }

  return <div className={className}>{children}</div>
}


export default function TeamSection({ team, className='' }) {
  return team && (
    <section id="team" className={`${className}`}>
      <Splide hasTrack={false} options={{ perPage: 3, gap: '2vw' }}>
        <div className="container-main">
          <div className="centered-y justify-between">
            <h2 className="text-vw-7.75xl">Наша команда</h2>

            <div className="desktop-only splide__arrows centered mt-vw-4 -mr-vw-12">
              <button
                type="button"
                className="splide__arrow splide__arrow--prev centered disabled:opacity-20 w-vw-24 h-vw-24 transition-opacity mr-vw-2"
              >
                <ArrowSvg className="w-vw-16 rotate-180" />
              </button>

              <button
                type="button"
                className="splide__arrow splide__arrow--next centered disabled:opacity-20 w-vw-24 h-vw-24 transition-opacity"
              >
                <ArrowSvg className="w-vw-16" />
              </button>
            </div>
          </div>

          <SplideTrack className="desktop-only mt-vw-6">
            {team.map((member, idx) => (
              <SplideSlide key={idx}>
                <MemberWrapper member={member} className="group w-full">
                  <div className="aspect-square rounded-vw-5xl overflow-hidden relative">
                    <Image
                      src={member.image.src}
                      alt={member.image.alt}
                      fill={true}
                      sizes="40vw"
                      className="w-full h-full object-cover"
                      style={{ objectPosition: '50% 15%' }}
                    />

                    <div className="absolute inset-0 opaque-gradient-1 opacity-50" />
                    <div className="absolute inset-0 opaque-gradient-2 opacity-50" />
                  </div>

                  <p className="text-vw-4.5xl leading-[1.2] mt-vw-2">
                    {member.firstName}<br />{member.lastName} {member.page && (
                      <ArrowAngleSvg
                        className="inline w-vw-6 group-hover:scale-125 origin-bottom-left transition-transform"
                      />
                    )}
                  </p>

                  <p className="text-vw-2xl leading-tight opacity-30">
                    {member.jobTitle}
                  </p>
                </MemberWrapper>
              </SplideSlide>
            ))}
          </SplideTrack>
        </div>

        <div className="mobile-only flex items-start overflow-auto pl-main mt-vw-8">
          {team.map((member, idx) => (
            <MemberWrapper key={idx} member={member} className={`w-vw-104 flex-shrink-0 pb-vw-10 ${idx !== 0 ? 'ml-vw-4' : ''}`}>
              <div className="w-full h-vw-152 rounded-vw-5xl overflow-hidden relative">
                <Image
                  src={member.image.src}
                  alt={member.image.alt}
                  fill={true}
                  sizes="40vw"
                  className="w-full h-full object-cover object-top"
                />

                <div className="absolute inset-0 opaque-gradient-1 opacity-50" />
                <div className="absolute inset-0 opaque-gradient-2 opacity-50" />
              </div>

              <p className="text-vw-5xl leading-[1.2] mt-vw-2">
                {member.firstName}<br />{member.lastName} {member.page && (
                  <ArrowAngleSvg className="inline w-vw-6 origin-bottom-left transition-transform" />
                )}
              </p>

              <p className="text-vw-3xl leading-tight opacity-30 sm:mt-0 mt-vw-3">
                {member.jobTitle}
              </p>
            </MemberWrapper>
          ))}

          <div className="flex-shrink-0 w-vw-8 h-vw-8" />
        </div>
      </Splide>
    </section>
  )
}
