import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';

import frame1 from '../../../public/img/hero/frame1.png';
import frame2 from '../../../public/img/hero/frame2.png';
import frame3 from '../../../public/img/hero/frame3.png';
import frame4 from '../../../public/img/hero/frame4.png';
import frame5 from '../../../public/img/hero/frame5.png';
import MagicWandSvg from '../../../public/svg/magic-wand.svg';


const FRAME_TO_ROTATION_MAP = {
  1: 55,
  2: -40,
  3: 78,
  4: -67,
  5: 20
}


const CirclingLogo = ({ activeFrame, className='' }) => {
  return (
    <svg
      className={`${className} transition-transform`}
      viewBox="0 0 771 771"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${FRAME_TO_ROTATION_MAP[activeFrame]}deg)`,
        transitionDuration: '1500ms'
      }}
    >
      <path d="M32.666 231.546L240.104 307.768C268.028 255.919 322.547 220.91 385.488 220.91C476.796 220.91 550.374 294.917 550.374 385.763C550.374 477.053 476.352 550.617 385.488 550.617C322.547 550.617 268.028 515.608 240.104 463.759L32.666 539.981C92.0606 676.03 227.693 770.865 385.488 770.865C598.244 770.865 770.666 598.478 770.666 385.763C770.666 173.049 598.244 0.661621 385.488 0.66163C227.693 0.661637 92.0606 95.4969 32.666 231.546Z" fill="#C4D058" />
    </svg>
  )
}


const TextFrames = ({ activeFrame, onNextFrame, className='' }) => {
  return (
    <div className={`flex flex-col sm:w-vw-88 w-vw-80 sm:h-vw-32 h-vw-60 relative ${className}`}>
      <p className="sm:text-vw-2xl text-vw-3xl">
        Мы предлагаем
      </p>

      <div className="leading-[1.1] relative mt-vw-1">
        <p
          className="sm:text-vw-xl text-vw-3xl absolute top-0 left-0 transition-[transform,opacity] duration-500"
          style={{
            transform: activeFrame === 1 ? 'translateY(0vw)' : activeFrame === 2 ? 'translateY(10vw)' : 'translateY(-10vw)',
            opacity: activeFrame === 1 ? 0.25 : 0,
            transitionDuration: (activeFrame === 2 || activeFrame === 1) ? '800ms' : '0s'
          }}
        >
          Сданный дом с видом на заповедник
        </p>

        <p
          className="sm:text-vw-xl text-vw-3xl absolute top-0 left-0 transition-[transform,opacity] duration-500"
          style={{
            transform: activeFrame === 2 ? 'translateY(0vw)' : activeFrame === 3 ? 'translateY(10vw)' : 'translateY(-10vw)',
            opacity: activeFrame === 2 ? 0.25 : 0,
            transitionDuration: (activeFrame === 3 || activeFrame === 2) ? '800ms' : '0s'
          }}
        >
          Лучшее соотношение цена / качество Приморского района
        </p>

        <p
          className="sm:text-vw-xl text-vw-3xl absolute top-0 left-0 transition-[transform,opacity] duration-500"
          style={{
            transform: activeFrame === 3 ? 'translateY(0vw)' : activeFrame === 4 ? 'translateY(10vw)' : 'translateY(-10vw)',
            opacity: activeFrame === 3 ? 0.25 : 0,
            transitionDuration: (activeFrame === 4 || activeFrame === 3) ? '800ms' : '0s'
          }}
        >
          Идеальный проект для сдачи в аренду у Петроградки
        </p>

        <p
          className="sm:text-vw-xl text-vw-3xl absolute top-0 left-0 transition-[transform,opacity] duration-500"
          style={{
            transform: activeFrame === 4 ? 'translateY(0vw)' : activeFrame === 5 ? 'translateY(10vw)' : 'translateY(-10vw)',
            opacity: activeFrame === 4 ? 0.25 : 0,
            transitionDuration: (activeFrame === 5 || activeFrame === 4) ? '800ms' : '0s'
          }}
        >
          Адмиралтейский район в пешей доступности от метро
        </p>

        <p
          className="sm:text-vw-xl text-vw-3xl absolute top-0 left-0 transition-[transform,opacity] duration-500"
          style={{
            transform: activeFrame === 5 ? 'translateY(0vw)' : activeFrame === 1 ? 'translateY(10vw)' : 'translateY(-10vw)',
            opacity: activeFrame === 5 ? 0.25 : 0,
            transitionDuration: (activeFrame === 1 || activeFrame === 5) ? '800ms' : '0s'
          }}
        >
          Уникальная архитектура с видом на Финский залив
        </p>
      </div>

      <button className="absolute bottom-0" type="button" onClick={onNextFrame}>
        <div className="flex items-center mt-vw-2">
          <MagicWandSvg className="sm:w-vw-6 w-vw-10" />

          <p className="sm:text-vw-base text-vw-3xl text-primary ml-vw-2">А еще?</p>
        </div>
      </button>
    </div>
  )
}

const ImageFrames = ({ activeFrame, className='' }) => {
  return (
    <div className={`${className}`}>
      <div
        className="sm:w-[40vw] w-[80vw] absolute inset-y-0 right-0 transition-transform"
        style={{
          transformOrigin: '130% 50%',
          transform: activeFrame === 1 ? 'rotate(0deg)' : activeFrame === 2 ? 'rotate(-180deg)' : 'rotate(180deg)',
          transitionDuration: (activeFrame === 2 || activeFrame === 1) ? '1s' : '0s'
        }}
      >
        <Image
          src={frame1}
          alt="сергей петербургский | frame 1"
          fill={true}
          sizes="(min-width: 640px) 40vw, 80vw"
          className="object-contain"
          priority
        />
      </div>

      <div
        className="sm:w-[40vw] w-[80vw] absolute inset-y-0 right-0 transition-transform"
        style={{
          transformOrigin: '130% 50%',
          transform: activeFrame === 2 ? 'rotate(0deg)' : activeFrame === 3 ? 'rotate(-180deg)' : 'rotate(180deg)',
          transitionDuration: (activeFrame === 3 || activeFrame === 2) ? '800ms' : '0s'
        }}
      >
        <Image
          src={frame2}
          alt="сергей петербургский | frame 2"
          fill={true}
          sizes="(min-width: 640px) 40vw, 80vw"
          className="object-contain"
          priority
        />
      </div>

      <div
        className="sm:w-[40vw] w-[80vw] absolute inset-y-0 right-0 transition-transform"
        style={{
          transformOrigin: '130% 50%',
          transform: activeFrame === 3 ? 'rotate(0deg)' : activeFrame === 4 ? 'rotate(-180deg)' : 'rotate(180deg)',
          transitionDuration: (activeFrame === 4 || activeFrame === 3) ? '800ms' : '0s'
        }}
      >
        <Image
          src={frame3}
          alt="сергей петербургский | frame 3"
          fill={true}
          sizes="(min-width: 640px) 40vw, 80vw"
          className="object-contain"
          priority
        />
      </div>

      <div
        className="sm:w-[40vw] w-[80vw] absolute inset-y-0 right-0 transition-transform"
        style={{
          transformOrigin: '130% 50%',
          transform: activeFrame === 4 ? 'rotate(0deg)' : activeFrame === 5 ? 'rotate(-180deg)' : 'rotate(180deg)',
          transitionDuration: (activeFrame === 5 || activeFrame === 4) ? '800ms' : '0s'
        }}
      >
        <Image
          src={frame4}
          alt="сергей петербургский | frame 4"
          fill={true}
          sizes="(min-width: 640px) 40vw, 80vw"
          className="object-contain"
          priority
        />
      </div>

      <div
        className="sm:w-[40vw] w-[80vw] absolute inset-y-0 right-0 transition-transform"
        style={{
          transformOrigin: '130% 50%',
          transform: activeFrame === 5 ? 'rotate(0deg)' : activeFrame === 1 ? 'rotate(-180deg)' : 'rotate(180deg)',
          transitionDuration: (activeFrame === 1 || activeFrame === 5) ? '800ms' : '0s'
        }}
      >
        <Image
          src={frame5}
          alt="сергей петербургский | frame 5"
          fill={true}
          sizes="(min-width: 640px) 40vw, 80vw"
          className="object-contain"
          priority
        />
      </div>
    </div>
  )
}


export default function HeroSection() {
  const [activeFrame, setActiveFrame] = useState(5);
  const animInterval = useRef(null);

  const setNextFrame = () => setActiveFrame(cur => cur === 5 ? 1 : cur + 1);

  const beginInterval = () => {
    animInterval.current = setInterval(() => {
      setNextFrame()
    }, 8000)
  }

  const handleNextFrame = () => {
    clearInterval(animInterval.current);
    setNextFrame()
    beginInterval();
  }

  useEffect(() => {
    beginInterval();
    return () => clearInterval(animInterval.current)
  }, [])

  return (
    <section className="w-full sm:h-screen overflow-hidden pt-[var(--header-height)] mt-[calc(var(--header-height)*-1)] relative">
      <div className="container-main flex flex-col justify-center items-start h-full relative">
        <h1 className="sm:text-vw-6.5xl text-vw-7.75xl sm:leading-tight leading-[1.1] sm:mb-vw-8 sm:mt-0 mt-vw-16">
          <span className="desktop-only">Камерное агентство<br />недвижимости<br />в Санкт-Петербурге</span>
          <span className="mobile-only">Камерное<br />агентство<br />недвижимости<br />в Санкт-<br />Петербурге</span>
        </h1>

        <button
          type="button"
          onClick={() => document.getElementById('request').scrollIntoView()}
          className="group button-primary relative sm:mt-0 mt-vw-20 sm:mb-vw-20"
        >
          Подобрать квартиру

          <span
            className={`
              sm:text-vw-sm text-vw-3xl text-white
              bg-secondary group-hover:bg-primary sm:rounded-vw-2xl rounded-vw-4xl
              absolute right-0 top-0 -translate-y-1/2
              transition-colors
              sm:px-vw-3 px-vw-7 sm:pt-vw-1 pt-vw-2.5 sm:pb-vw-1.5 pb-vw-3.5
            `}
          >
            Бесплатно
          </span>
        </button>

        <div className="desktop-only absolute right-vw-104 bottom-vw-16">
          <TextFrames activeFrame={activeFrame} onNextFrame={handleNextFrame} />
        </div>

        <div className="mobile-only w-full h-vw-176 relative">
          <div className="flex items-center h-full">
            <TextFrames activeFrame={activeFrame} onNextFrame={handleNextFrame} />
          </div>

          <div className="absolute top-1/2 -right-vw-48 -translate-y-1/2">
            <CirclingLogo
              activeFrame={activeFrame}
              className="h-vw-144"
            />

            <ImageFrames
              activeFrame={activeFrame}
              className="w-full absolute -top-vw-20 -bottom-vw-10 right-vw-16"
            />
          </div>
        </div>
      </div>

      <div className="desktop-only absolute -top-vw-24 right-vw-10 translate-x-1/2">
        <CirclingLogo
          activeFrame={activeFrame}
          className="h-[104vh] max-w-vw-256"
        />
      </div>

      <ImageFrames
        activeFrame={activeFrame}
        className="desktop-only w-1/2 absolute top-0 -bottom-vw-16 -right-vw-36"
      />
    </section>
  )
}
