import Image from 'next/image';

import wwdImageDesktop from '../../../public/img/wwd/desktop.png';
import wwdImageMobile from '../../../public/img/wwd/mobile.png';


export default function WhatWeDoSection() {
  return (
    <section id="about" className="w-full h-[336vh]">
      <div className="w-full h-[205vh] relative">
        <div className="sticky top-0">
          <div className="absolute inset-x-0 top-0 h-screen">
            <div className="desktop-only w-vw-72 h-vw-24 bg-primary absolute top-vw-14 -left-vw-32 z-[-1] rotate-[-60deg]" />
            <div className="desktop-only w-vw-96 h-vw-24 bg-primary absolute -bottom-vw-28 right-vw-2 z-[-1] rotate-[-23deg]" />
            <div className="mobile-only w-vw-96 h-vw-36 bg-primary absolute bottom-vw-2 -left-vw-32 z-[-1] rotate-[39deg]" />

            <Image
              src={wwdImageDesktop}
              alt="сергей петербургский | о нас"
              fill={true}
              sizes="100vw"
              quality={90}
              priority
              className="desktop-only object-cover"
            />

            <Image
              src={wwdImageMobile}
              alt="сергей петербургский | о нас"
              fill={true}
              sizes="100vh"
              quality={90}
              priority
              className="mobile-only object-cover"
            />
          </div>
        </div>

        <div className="centered w-full h-screen sm:pl-vw-32 sm:pr-0 pr-vw-10">
          <p className="sm:text-vw-3xl text-vw-5xl">
            Продаем только<br />те новостройки,<br />в которых жили<br />бы сами
          </p>
        </div>

        <div className="centered w-full h-screen sm:pl-vw-32 sm:pr-0 pr-vw-10">
          <p className="sm:text-vw-3xl text-vw-5xl">
            Согласовываем<br />персональные<br />скидки
          </p>
        </div>

        <div className="centered w-full h-screen sm:pl-vw-32 sm:pr-0 pr-vw-10">
          <p className="sm:text-vw-3xl text-vw-5xl">
            Работа бесплатна<br />для вас, комиссию<br />платит застройщик
          </p>
        </div>
      </div>
    </section>
  )
}
