import { useState } from 'react'
import Link from 'next/link'

import CommonForm from '../../../components/CommonForm/CommonForm'
import CommonInput from '../../../components/CommonInput/CommonInput'
import RadioGroup from '../../../components/RadioGroup/RadioGroup'
import Checkbox from '../../../components/Checkbox/Checkbox'

import LogoSvg from '../../../public/svg/logo.svg'
import ArrowSvg from '../../../public/svg/arrow.svg'
import handsImg from '../../../public/img/request/hands.png'
import handshakeImg from '../../../public/img/request/handshake.png'

import { sendApartmentSelectionRequest } from '../../../api/wagtail'


const Step = ({ title, shown, children }) => {
  return (
    <div className="absolute inset-0" style={{ display: shown ? '' : 'none' }}>
      <div className="flex flex-col w-full h-full sm:pb-vw-20 pb-vw-32">
        <div className="centered sm:pt-vw-14 pt-vw-20 sm:px-0 px-vw-14">
          <h4 className="sm:text-vw-4.5xl text-vw-6.5xl text-center leading-tight">{title}</h4>
        </div>

        <div className="centered flex-grow w-full sm:px-0 px-vw-14">
          {children}
        </div>
      </div>
    </div>
  )
}


export default function RequestSection({ siteSetting, csrfToken, className='' }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  // const [error, setError] = useState(false);

  const handleWatch = ({ purpose, rooms, payment, checkIn, budget }) => {
    if (budget) {
      setStep(6)
    } else if (checkIn) {
      setStep(5)
    } else if (payment) {
      setStep(4)
    } else if (rooms) {
      setStep(3)
    } else if (purpose) {
      setStep(2)
    }
  }

  const handleSubmit = (data) => {
    setLoading(true)
    return sendApartmentSelectionRequest(data, { csrfToken })
  }

  const handleSuccess = () => {
    setSuccess(true)
    setLoading(false)
  }

  return (
    <section id="request" className={className}>
      <div className="sm:container-main">
        <div className="sm:px-0 px-main">
          <h2 className="text-vw-7.75xl leading-tight">Оставьте <br className="sm:hidden" />заявку</h2>
        </div>

        <CommonForm
          defaultValues={{
            purpose: '',
            rooms: '',
            payment: '',
            checkIn: '',
            budget: '',
            name: '',
            phone: '',
            policy: true,
          }}
          onWatch={handleWatch}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onError={() => setLoading(false)}
          className="sm:mt-vw-3 mt-vw-16"
        >
          {({ errors, isSubmitting }) => (
            <div className="w-full sm:h-vw-160 h-vw-328 bg-primary sm:rounded-vw-5xl rounded-vw-7xl relative overflow-hidden">
              <div className="absolute sm:inset-0 inset-x-0 sm:h-auto h-vw-112 bottom-vw-10">
                <img className="w-full h-full object-cover" src={handsImg.src} alt="сергей петербургский | оставьте заявку" />
              </div>

              <div className="w-full h-full relative">
                <Step title={<>Цель покупки квартиры?</>} shown={step === 1}>
                  <RadioGroup
                    name="purpose"
                    rules={{ required: true }}
                    options={[
                      { value: 'accommodation', label: 'Для проживания' },
                      { value: 'investment', label: 'Для инвестиций' },
                      { value: 'kids', label: 'Для детей на будущее' },
                      { value: 'parents', label: 'Для родителей' },
                    ]}
                  />
                </Step>

                <Step title={<>Сколько комнат должно<br />быть в вашей квартире?</>} shown={step === 2}>
                  <RadioGroup
                    name="rooms"
                    rules={{ required: true }}
                    options={[
                      { value: 'studio', label: 'Студия' },
                      { value: '1room', label: '1-комнатная' },
                      { value: '2room', label: '2-комнатная' },
                      { value: '3room', label: '3-комнатная' },
                      { value: '3plus', label: 'Более 3-х комнат' },
                    ]}
                  />
                </Step>

                <Step title={<>Какая форма оплаты<br />вам удобна?</>} shown={step === 3}>
                  <RadioGroup
                    name="payment"
                    rules={{ required: true }}
                    options={[
                      { value: 'mortgage', label: 'Ипотека' },
                      { value: 'installment', label: 'Рассрочка' },
                      { value: 'cash', label: 'Наличная оплата' },
                      { value: 'other', label: 'Другое' },
                    ]}
                  />
                </Step>

                <Step title={<>Когда вы хотели бы переехать<br />в новую квартиру?</>} shown={step === 4}>
                  <RadioGroup
                    name="checkIn"
                    rules={{ required: true }}
                    options={[
                      { value: 'asap', label: 'Как можно быстрее' },
                      { value: 'year', label: 'В течение года' },
                      { value: '2to3year', label: 'Готовы ждать 2-3 года' },
                      { value: 'undecided', label: 'Не определились, рассмотрим разные варианты' },
                    ]}
                  />
                </Step>

                <Step title={<>Какой у вас бюджет?</>} shown={step === 5}>
                  <RadioGroup
                    name="budget"
                    rules={{ required: true }}
                    options={[
                      { value: '3to6mil', label: '3–6 миллионов' },
                      { value: '6to10mil', label: '6–10 миллионов' },
                      { value: '10to15mil', label: '10–15 миллионов' },
                      { value: '15plus', label: 'Более 15 миллионов' },
                    ]}
                  />
                </Step>

                <Step title={<>Оставьте <span className="sm:inline hidden">контактные</span> данные</>} shown={step === 6}>
                  <div className="flex flex-col items-center">
                    <CommonInput
                      name="name"
                      rules={{ required: true }}
                      type="text"
                      placeholder="Имя"
                      className="mb-vw-4"
                    />
                    <CommonInput
                      name="phone"
                      rules={{ required: true }}
                      type="tel"
                      placeholder="Телефон"
                    />

                    <div className="centered-x sm:my-vw-6 my-vw-12">
                      {errors.root && (
                        <p className="text-vw-base text-red-700">{errors.root.message || "Неизвестная ошибка"}</p>
                      )}
                    </div>

                    <button
                      type="submit"
                      className={`
                        button-secondary sm:w-vw-72
                        sm:px-0 px-vw-28
                        ${isSubmitting ? 'pointer-events-none' : ''}`}
                    >
                      Отправить
                    </button>

                    <Checkbox
                      name="policy"
                      rules={{ required: true }}
                      text={
                        <>
                          Нажимая на кнопку, вы даете согласие<br />на обработку <Link href={siteSetting.privacyPolicyPage.href} className="underline">персональных данных</Link>
                        </>
                      }
                      className="sm:mt-vw-6 mt-vw-8"
                    />
                  </div>
                </Step>
              </div>

              <div className="centered-x absolute inset-x-0 bottom-vw-9">
                <button type="button" onClick={() => setStep(step !== 1 ? step - 1 : step)}>
                  <ArrowSvg className="sm:w-vw-6 w-vw-12 [&>*]:fill-white rotate-180" />
                </button>

                <p className="sm:text-vw-3xl text-vw-7xl text-white text-center sm:mx-vw-10 mx-vw-14 sm:mb-vw-1.5 mb-vw-4">
                  {step}/6
                </p>

                <button type="button" onClick={() => setStep(step !== 6 ? step + 1 : 6)}>
                  <ArrowSvg className="sm:w-vw-6 w-vw-12 [&>*]:fill-white" />
                </button>
              </div>

              <div
                className="centered absolute inset-0 z-10 bg-primary transition-all"
                style={{
                  visibility: success ? 'visible' : 'hidden',
                  opacity: success ? 1 : 0
                }}
              >
                <LogoSvg className="w-vw-120" />

                <div className="absolute sm:inset-0 inset-x-0 inset-y-vw-60">
                  <img className="w-full h-full object-cover" src={handshakeImg.src} alt="сергей петербургский | скоро свяжемся с вами" />
                </div>

                <p className="text-vw-5xl text-secondary leading-[1.1] absolute right-vw-28 bottom-vw-14">
                  Скоро<br />свяжемся!
                </p>
              </div>

              <div
                className="centered flex-col absolute inset-0 z-30 bg-primary transition-all"
                style={{
                  visibility: loading ? 'visible' : 'hidden',
                  opacity: loading ? 1 : 0
                }}
              >
                <LogoSvg className="w-vw-52 h-vw-52 animate-spin" />
              </div>
            </div>
          )}
        </CommonForm>
      </div>
    </section>
  )
}
